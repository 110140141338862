import React from 'react';
import { useField, useFormikContext } from 'formik';
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
  PhoneInputProps,
} from 'react-international-phone';

interface InputPhoneNumberProps extends Partial<PhoneInputProps> {
  name: string;
  label?: string;
  secondlabel?: React.ReactNode;
  required?: boolean;
  style?: React.CSSProperties;
}

const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return ['id'].includes(iso2);
});

const InputPhoneNumber: React.FC<InputPhoneNumberProps> = ({
  name,
  style,
  label,
  secondlabel,
  required = false,
  ...props
}) => {
  const { setFieldValue } = useFormikContext<Record<string, string>>();
  const [field, meta] = useField(name);

  const handlePhoneChange = (value: string) => {
    setFieldValue(name, value);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between gap-1">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </label>
          {required && (
            <label className="block text-xs font-light leading-6 text-gray-400">{`(required)`}</label>
          )}
        </div>
        {secondlabel ? <div>{secondlabel}</div> : null}
      </div>
      <div className="mt-2">
        <PhoneInput
          defaultCountry="id"
          value={field.value}
          onChange={handlePhoneChange}
          style={style}
          disableCountryGuess={false}
          countries={countries}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <div className="text-red-500 mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default InputPhoneNumber;
