import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { IconCopy } from '@tabler/icons-react';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';

import { get } from 'network/api';

import Tabs from 'components/Tabs';
import Image from 'components/Image';
import { RenderBankLogo } from 'components/RenderBankPaymentMethod';

type PaymentResultData = {
  type: string | undefined;
  bank: string | undefined;
  va_number: string | undefined;
  account_name: string | undefined;
  qr_content: string | undefined;
};

type GuideInfoData = {
  payment_channel: string;
  payment_instruction: string;
};

type ApiError = {
  detail: string;
  status_code: number;
};

interface PaymentResultProps {
  data: PaymentResultData | null;
  merchant: string;
}

const PaymentResult: React.FC<PaymentResultProps> = ({ data, merchant }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [tabData, setTabData] = useState<GuideInfoData[]>([]);

  const getPaymentInfo = async () => {
    return get(`/checkout/v1/payment/info`);
  };

  const {
    data: dataInfo,
    isLoading,
    error,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useQuery<any, ApiError>({
    queryKey: ['getPaymentInfo'],
    queryFn: getPaymentInfo,
    retry: false,
  });

  useEffect(() => {
    if (data?.bank && dataInfo) {
      const bankName = data.bank.split('_')[0].toLowerCase();
      const matchingBank = dataInfo.data.find(
        (bank: { bank_name: string }) =>
          bank.bank_name.toLowerCase() === bankName
      );

      if (matchingBank) {
        setTabData(matchingBank.payment_guide_detail);
      }
    }
  }, [data?.bank, dataInfo]);

  useEffect(() => {
    if (error) {
      toast.error(
        error ? error.detail : 'Terjadi kesalahan yang tidak diketahui'
      );
    }
  }, [error]);

  const handleTabClick = (params: number) => {
    setActiveTab(params);
  };

  const onCopyVa = async (va_number: string | undefined) => {
    if (!va_number) {
      toast.error('Invalid VA Number');
      return;
    }

    await navigator.clipboard.writeText(`${va_number}`);
    toast.success(`Copied ${va_number}`);
  };

  const checkPayment = () => {
    window.location.reload();
  };

  if (!data) {
    return (
      <div className="flex justify-center items-center h-[300px] text-gray-500 p-6 bg-[#F5F5F5] rounded-md shadow-md">
        Data not available. Please try again.
      </div>
    );
  }

  if (data.type === 'va') {
    return (
      <div>
        <div className="px-4 pt-2 pb-4 md:p-6 bg-[#F5F5F5] rounded-md shadow-md">
          <div>
            <div className="flex flex-col gap-4">
              <div className="block md:hidden">
                <RenderBankLogo
                  title={data?.bank ? data?.bank : 'Not Found'}
                  width="w-[120px]"
                  height="h-[50px]"
                />
              </div>

              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col">
                  <span className="text-base">Virtual Account Number</span>
                  <button
                    onClick={() => onCopyVa(data.va_number)}
                    className="flex flex-row gap-2 items-center cursor-pointer"
                  >
                    <span className="text-2xl font-semibold">
                      {data.va_number ? data.va_number : '-'}
                    </span>
                    <IconCopy className="w-5 h-5 text-gray-700" />
                  </button>
                </div>
                <div className="hidden md:block">
                  <RenderBankLogo
                    title={data?.bank ? data?.bank : 'Not Found'}
                    width="w-[120px]"
                    height="h-[50px]"
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col overflow-hidden ">
                  <span className="text-base">Merchant Name</span>
                  <div className="flex flex-row gap-2 items-center">
                    <span
                      className="text-xl font-semibold break-words"
                      style={{
                        wordBreak: 'break-word',
                      }}
                    >
                      {merchant ? merchant : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-300 my-6" />

          <div className="p-3 border-y border-r border-l-4 border-primary-main rounded-lg flex flex-col bg-primary-light">
            <div className="mb-3 font-semibold text-sm">
              Protect Yourself Against Fraud
            </div>
            <span className="font-light text-sm">
              Ensure the merchant’s name, Payment amount and details are correct
              Always check before proceeding with payment
            </span>
          </div>

          <div className="mt-4">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <div className="flex flex-col gap-2 w-full">
                  {[...Array(5)].map((itm: number, idx: number) => (
                    <div key={idx} className="w-full mx-auto">
                      <Skeleton className="skeleton" height={30} />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Tabs
                tabs={tabData.map((guide, index) => ({
                  id: index,
                  name: guide.payment_channel,
                  content: (
                    <div className="flex flex-col gap-3 py-6">
                      <span className="font-semibold">
                        {guide.payment_channel}
                      </span>
                      <ol className="mt-2 list-none list-inside">
                        {guide.payment_instruction
                          .split('\n')
                          .map((instruction, idx) => (
                            <li key={idx} className="mt-1">
                              {instruction.trim()}
                            </li>
                          ))}
                      </ol>
                    </div>
                  ),
                }))}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
              />
            )}
            <div className="hidden lg:block">
              <button
                onClick={() => checkPayment()}
                className={`my-3 w-full flex flex-row items-center gap-2 justify-center rounded-md border border-transparent bg-primary-main  px-4 py-2.5 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2`}
              >
                {'CHECK STATUS'}
              </button>

              <div className="mx-auto max-w-fit mt-1">
                <div className="flex flex-row items-center gap-2">
                  <span className="text-[10px]">Powered by</span>
                  <Image
                    className="object-contain w-[45px] h-[20px]"
                    src={'/images/logos/logo.png'}
                    alt="Business Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-[#F5F5F5] rounded-md shadow-md">
      <div className="p-3 border-y border-r border-l-4 border-primary-main rounded-lg flex flex-col bg-primary-light">
        <div className="mb-3 font-semibold text-sm">
          Protect Yourself Against Fraud
        </div>
        <span className="font-light text-sm">
          Ensure the merchant’s name, Payment amount and details are correct
          Always check before proceeding with payment
        </span>
      </div>
      <div className="h-auto w-[340px] mx-auto my-6">
        <QRCode
          size={100}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={data?.qr_content ? data?.qr_content : '-'}
          viewBox={`0 0 100 100`}
        />
      </div>
      <div className="hidden lg:block">
        <button
          onClick={() => checkPayment()}
          className={`my-3 w-full flex flex-row items-center gap-2 justify-center rounded-md border border-transparent bg-primary-main  px-4 py-2.5 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2`}
        >
          {'CHECK STATUS'}
        </button>

        <div className="mx-auto max-w-fit mt-1">
          <div className="flex flex-row items-center gap-2">
            <span className="text-[10px]">Powered by</span>
            <Image
              className="object-contain w-[45px] h-[20px]"
              src={'/images/logos/logo.png'}
              alt="Business Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentResult;
