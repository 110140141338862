const rupiah = (number: number): string => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};

const conversionToRupiah = (dataString: string): string => {
  const hanyaAngka = dataString.replace(/\D/g, '');
  const nilaiNumerik = parseInt(hanyaAngka, 10);
  const formattedRupiah = rupiah(nilaiNumerik);

  if (dataString.startsWith('-')) {
    return `- ${formattedRupiah}`;
  } else if (dataString.startsWith('+')) {
    return `+ ${formattedRupiah}`;
  } else {
    return formattedRupiah;
  }
};

export { rupiah, conversionToRupiah };
