import React from 'react';
import Image from 'components/Image';

interface BankLogo {
  code: string;
  name: string;
  value: string;
}

const imageBank: BankLogo[] = [
  {
    code: 'BCA_VA',
    name: 'BCA',
    value: 'bca-logo.png',
  },
  {
    code: 'BRI_VA',
    name: 'BRI',
    value: 'bri-logo.png',
  },
  {
    code: 'MAYBANK_VA',
    name: 'MAYBANK',
    value: 'may-logo.png',
  },
  {
    code: 'MANDIRI_VA',
    name: 'MANDIRI',
    value: 'mandiri-logo.png',
  },
  {
    code: 'CIMB_VA',
    name: 'CIMB',
    value: 'cimb-logo.png',
  },
  {
    code: 'PERMATA_VA',
    name: 'PERMATA',
    value: 'permata-logo.png',
  },
  {
    code: 'BNI_VA',
    name: 'BNI',
    value: 'bni-logo.png',
  },
  {
    code: 'QRIS',
    name: 'QRIS',
    value: 'qris.png',
  },
];

interface RenderBankLogoProps {
  title: string;
  idx?: number;
  width?: string;
  height?: string;
}

const RenderBankLogo: React.FC<RenderBankLogoProps> = ({
  title,
  idx,
  width,
  height,
}) => {
  const matchingItem = imageBank.find(
    (bank: BankLogo) => bank.code.toLowerCase() === title.toLowerCase()
  );

  if (!title || !matchingItem) {
    return (
      <div className="py-2" key={idx}>
        <div className="flex flex-row w-full items-center">
          <span className="text-xs font-bold ml-2">
            {title?.toUpperCase() ?? '-'}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      key={idx}
      className={`${width ? width : 'w-[50px] md:w-[70px]'} ${
        height ? height : 'h-[25px]'
      }`}
    >
      <Image
        className="object-contain w-full h-full"
        src={`/images/logos/${matchingItem.value}`}
        alt={`${matchingItem.name} icon`}
      />
    </div>
  );
};

const RenderBankLogoName: React.FC<RenderBankLogoProps> = ({
  title,
  idx,
  width,
  height,
}) => {
  const matchingItem = imageBank.find(
    (bank: BankLogo) => bank.code.toLowerCase() === title.toLowerCase()
  );

  if (!title || !matchingItem) {
    return (
      <div className="py-2" key={idx}>
        <div className="flex flex-row w-full items-center">
          <span className="text-xs font-medium ml-2">
            {title?.toUpperCase() ?? '-'}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row gap-3 items-center" key={idx}>
      <div
        className={`${width ? width : 'w-[75px]'} ${
          height ? height : 'h-[25px]'
        }`}
      >
        <Image
          className="object-contain w-full h-full"
          src={`/images/logos/${matchingItem.value}`}
          alt={`${matchingItem.name} icon`}
        />
      </div>
      <div className="font-medium text-xs block">{matchingItem.name}</div>
    </div>
  );
};

export { RenderBankLogo, RenderBankLogoName };
