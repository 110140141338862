import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { post } from 'network/api';
import { toast } from 'react-hot-toast';

import InputComponent from 'components/Input';
import Image from 'components/Image';
import InputPhoneNumber from 'components/InputPhoneNumber';
import Spinner from 'components/Spinner';
import BusinessName from './BusinessName';
import SectionSummaryOrderMobileProps from './SectionSummaryOrderMobile';
import { DataDetailCheckout } from '../types';

interface FormUserProps {
  data: DataDetailCheckout;
  setNameCustomer: (value: string) => void;
}

const FormUser: React.FC<FormUserProps> = ({ data, setNameCustomer }) => {
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = async (
    values: {
      name: string;
      email: string;
      phone_number: string;
      address: string;
    },
    invoice_id: string
  ) => {
    const formattedPhoneNumber =
      values.phone_number === '+62'
        ? ''
        : values.phone_number.replace(/^\+/, '');
    const payload = {
      name: values.name,
      email: values.email,
      phone_number: formattedPhoneNumber,
      address: values.address,
    };

    setLoading(true);
    await post(`/checkout/v1/customer?invoice_number=${invoice_id}`, payload)
      .then(() => {
        setNameCustomer(values.name);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.detail);
        setLoading(false);
      });
  };

  return (
    <div className="pb-6 pt-8 px-4 lg:px-0">
      <BusinessName data={data} />
      <div className="hidden lg:flex flex-row justify-between items-center my-8 w-full">
        <div className="flex flex-col gap-[3px]">
          <span className="text-xs text-gray-800 font-medium block">
            Order{' '}
            <span className="text-xs text-gray-800">
              ({data?.po_number ? data?.po_number : '-'})
            </span>
          </span>
        </div>
        <div className="flex flex-row gap-3 justify-between items-center rounded-md px-2.5 py-1.5 border border-gray-200 bg-neutral-50 shadow-sm">
          <span className="font-bold text-sm capitalize">
            {data?.currency ? data?.currency : 'IDR'}
          </span>
          <div className="w-6 h-6 flex flex-col rounded border overflow-hidden">
            <div className="w-full flex-1 bg-red-600" />
            <div className="w-full flex-1 bg-white" />
          </div>
        </div>
      </div>
      <SectionSummaryOrderMobileProps data={data} />
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone_number: '',
          address: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Please enter your name'),
          phone_number: Yup.string()
            .test(
              'valid-phone-number',
              'Phone number must be valid and follow Indonesian format',
              (value) => {
                if (value === '+62') return true;
                return /^(?:\+62|62|0)[2-9][0-9]{7,12}$/.test(value || '');
              }
            )
            .nullable(),
        })}
        onSubmit={async (values) => {
          handleOnSubmit(values, data?.invoice_number);
        }}
        enableReinitialize={true}
      >
        {({ handleSubmit }) => (
          <form className="mt-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-x-4 gap-y-6 ">
              <div className="col-span-full ">
                <InputComponent name="name" type="text" label="Name" required />
              </div>
              <div className="col-span-full">
                <InputComponent name="email" type="email" label="Email" />
              </div>
              <div className="col-span-full">
                <InputPhoneNumber name="phone_number" label="Phone Number" />
                {/* <InputComponent
                  name="phone_number"
                  type="text"
                  label="Phone Number"
                /> */}
              </div>
              <div className="col-span-full">
                <InputComponent name="address" type="text" label="Address" />
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`${
                loading
                  ? 'bg-gray-200 hover:bg-gray-400'
                  : 'bg-primary-main hover:bg-primary-dark'
              } mt-10 w-full flex flex-row items-center gap-2 justify-center rounded-md border border-transparent px-4 py-2.5 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2`}
            >
              {loading ? <Spinner /> : <span>CONTINUE</span>}
            </button>
          </form>
        )}
      </Formik>
      <div className="mx-auto max-w-fit mt-10">
        <div className="flex flex-row items-center gap-2">
          <span className="text-xs">Powered by</span>
          <Image
            className="object-contain w-[50px] h-[25px]"
            src={'/images/logos/logo.png'}
            alt="Business Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default FormUser;
