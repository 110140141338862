import { useLocation } from 'react-router-dom';
import Image from 'components/Image';

const ErrorPage = () => {
  const location = useLocation();
  const message =
    location.state?.message || 'Terjadi kesalahan yang tidak diketahui';

  return (
    <main className="h-screen flex justify-center items-center bg-white px-6 lg:px-8">
      <div className="text-center">
        <Image
          className="mt-4 object-contain w-[300px] h-[80px] mx-auto"
          src={'/images/logos/logo.png'}
          alt="Business Logo"
        />
        <Image
          className="mt-4 object-contain w-[300px] h-[300px] mx-auto"
          src={'/images/logos/404.gif'}
          alt="Business Logo"
        />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 lg:text-5xl max-w-xs lg:max-w-lg mx-auto">
          {message}
        </h1>
        <p className="mt-6 text-sm lg:text-base leading-7 text-gray-600 max-w-md mx-auto">
          Maaf, terjadi kesalahan saat membuka invoice Anda. Mungkin invoice
          sudah kedaluwarsa atau tidak valid
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="text-sm font-semibold text-gray-900 flex flex-col"
          >
            <span aria-hidden="true">Contact support</span>
            <span aria-hidden="true">hello@doitpay.co</span>
          </a>
        </div>
      </div>
    </main>
  );
};

export default ErrorPage;
