import React, { ReactNode } from 'react';

function classNames(...classes: (string | boolean | undefined)[]): string {
  return classes.filter(Boolean).join(' ');
}

interface Tab {
  id: number;
  name: string;
  content: ReactNode;
  icon?: ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: number;
  handleTabClick: (tabIndex: number) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, handleTabClick }) => {
  const renderTabContent = () => {
    const activeTabContent = tabs.find((tab) => tab.id === activeTab);
    return activeTabContent ? activeTabContent.content : null;
  };

  return (
    <div>
      <div className="block">
        <nav
          className="flex flex-row overflow-x-auto border-b border-gray-300 w-full scrollbar-hide"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              onClick={() => handleTabClick(tabIdx)}
              key={tab.name}
              className={classNames(
                tabIdx === activeTab
                  ? 'border-b-2 border-primary-main text-primary-main -mb-px font-semibold'
                  : 'text-gray-400',
                'flex-grow-0 flex-shrink-0 relative flex flex-row items-center px-4 text-center text-sm font-light min-h-[36px] h-[36px]'
              )}
              aria-current={tabIdx === activeTab ? 'page' : undefined}
            >
              {tab.icon ? tab.icon : null}
              <span className="ml-3">{tab.name}</span>
            </button>
          ))}
        </nav>
      </div>
      <div>{renderTabContent()}</div>
    </div>
  );
};

export default Tabs;
