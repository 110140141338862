import Image from 'components/Image';
import { DataDetailCheckout } from '../types';

interface BusinessNameProps {
  data: DataDetailCheckout;
}

const BusinessName: React.FC<BusinessNameProps> = ({ data }) => {
  return (
    <div className="flex flex-row justify-center items-center">
      {data?.business.logo_path ? (
        <Image
          className="object-contain w-[150px] h-[80px]"
          src={data?.business.logo_path}
          alt="Business Logo"
        />
      ) : (
        <div className="flex flex-col items-center gap-1">
          <h3 className="text-2xl font-bold">
            {data?.merchant.name ? data?.merchant.name : '-'}
          </h3>
          <span className="text-xs font-medium">
            {data?.business.name ? data?.business.name : '-'}
          </span>
        </div>
      )}
    </div>
  );
};

export default BusinessName;
