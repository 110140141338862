import Skeleton from 'react-loading-skeleton';
import { IconShoppingCartFilled } from '@tabler/icons-react';

import Image from 'components/Image';
import Typography from 'components/Typography';

const LoadingPage = () => {
  return (
    <div className="h-screen">
      <main className="h-full grid grid-cols-6">
        <h1 className="sr-only">Checkout</h1>
        <section
          aria-labelledby="payment-heading"
          className="col-span-6 lg:col-span-4 overflow-y-auto"
        >
          <div className="px-4 lg:px-0 mx-auto max-w-xl h-full pt-8 ">
            <div className="w-full h-fit pb-6">
              <div className="w-[150px] mx-auto">
                <Skeleton className="skeleton" height={100} />
              </div>
            </div>
            <div className="w-full">
              <Skeleton className="skeleton" height={30} />
            </div>
            <div className="mt-3 w-full h-fit pb-6 flex flex-col gap-4">
              <Skeleton className="skeleton" height={60} />
              <Skeleton className="skeleton" height={60} />
              <Skeleton className="skeleton" height={60} />
              <Skeleton className="skeleton" height={60} />
              <Skeleton className="skeleton" height={60} />
              <div className="mx-auto max-w-fit mt-16">
                <div className="flex flex-row items-center gap-2">
                  <span className="text-xs">Powered by</span>
                  <Image
                    className="object-contain w-[50px] h-[25px]"
                    src={'/images/logos/logo.png'}
                    alt="Business Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          aria-labelledby="summary-heading"
          className="hidden md:block w-full flex-col bg-neutral-100 lg:flex col-span-2 relative overflow-y-auto"
        >
          <div className="h-[150vh] px-8 pt-8 pb-[120px]">
            <div className="flex flex-row items-center gap-2">
              <IconShoppingCartFilled />
              <Typography variant="h4" className="font-semibold">
                Order Summary
              </Typography>
            </div>

            <div className="flex flex-col gap-[2px] mt-1.5">
              <Typography variant="small">
                Invoice Number: <Skeleton className="skeleton" height={10} />
              </Typography>
              <Typography variant="small">
                Date: <Skeleton className="skeleton" height={10} />
              </Typography>
            </div>

            <div className="mt-6">
              <ul className="divide-y divide-gray-100 flex flex-col gap-[8px]">
                <li className="flex justify-center gap-x-6">
                  <div className="min-w-0 flex-auto">
                    <p className="text-xs text-gray-500 font-medium">Item</p>
                  </div>
                  <div className="shrink-0 flex flex-col items-end">
                    <p className="text-xs text-gray-500 font-medium">Price</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="sticky bottom-0 right-0 left-0 w-full bg-neutral-50 shadow-[0_35px_70px_-15px_rgba(0,0,0,0.3)] px-8 py-4">
            <div className="flex items-center justify-between text-gray-900 py-2">
              <dt className="text-gray-900 text-base font-medium">Subtotal</dt>
              <dd className="text-gray-900 text-lg font-bold">
                <Skeleton className="skeleton" width={80} height={30} />
              </dd>
            </div>
            <div className="flex flex-col gap-[5px] border-y border-gray-300 py-2">
              <div className="flex items-center justify-between text-gray-900">
                <dt className="text-gray-900 text-[14px] font-medium">Tax</dt>
                <dd className="text-gray-900 text-[14px] font-medium">
                  <Skeleton className="skeleton" width={80} height={20} />
                </dd>
              </div>
              <div className="flex items-center justify-between text-gray-900">
                <dt className="text-gray-900 text-[14px] font-medium">
                  Discount
                </dt>
                <dd className="text-gray-900 text-[14px] font-medium">
                  <Skeleton className="skeleton" width={80} height={20} />
                </dd>
              </div>
              <div className="flex items-center justify-between text-gray-900">
                <dt className="text-gray-900 text-[14px] font-medium">
                  Addtional Fee
                </dt>
                <dd className="text-gray-900 text-[14px] font-medium">
                  <Skeleton className="skeleton" width={80} height={20} />
                </dd>
              </div>
            </div>
            <div className="flex items-end justify-between text-gray-900 pt-2">
              <dt className="text-gray-900 text-base font-bold">Total</dt>
              <dd className="text-gray-900 text-2xl font-bold">
                <Skeleton className="skeleton" width={80} height={30} />
              </dd>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LoadingPage;
